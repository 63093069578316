<template>
  <v-dialog persistent @keydown.esc="close" v-model="dDialogAddAccount" :width="screenWidth + '%'">
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-account">
          <p class="title-add-account">Nueva cuenta</p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-text-fields">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field v-model="sName" @keyup="validateForm()" label="Nombre de la cuenta" placeholder="Nombre de la cuenta..."
                  class="global-text-field" color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)" persistent-placeholder outlined height="50px">
                  <template slot="label">
                    <span>Nombre de la cuenta<span class="important-data">*</span></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-select v-model="sAccountType"  :disabled="bLoadingAccountType" :loading="bLoadingAccountType"
                  :items="itemAccountType" @change="validateForm()" item-text="sName" item-value="sAccountTypeId"
                  class="global-select" color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)" outlined>
                  <template slot="label">
                    <span>Tipo de cuenta
                      <span class="important-data">*</span></span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-select v-model="sAssociatedCurrency" :disabled="bLoadingAssociatedCurrency"
                  :loading="bLoadingAssociatedCurrency" :items="itemAssociatedCurrency" @change="validateForm()"
                  item-text="sName" item-value="sCurrencyId" class="global-select"
                  color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined>
                  <template slot="label">
                    <span>Moneda asociada
                      <span class="important-data">*</span></span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-select v-model="sNameInstitution" :disabled="bLoadingInstitutionName"
                  :loading="bLoadingInstitutionName" :items="itemNameInstitution" @change="validateForm()"
                  item-text="sName" item-value="sFinancialInstitutionId" class="global-select"
                  color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined>
                  <template slot="label">
                    <span>Nombre de la institución<span class="important-data">*</span></span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-sidebar-global mb-3"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn class="global-btn-primary" :disabled="!bAdd" :loading="bLoading" @click="addAccount()">
              Crear cuenta
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dDialogAddAccount: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      bAdd: false,
      bLoading: false,
      sName: "",
      sAccountType: null,
      itemAccountType: [],
      sAssociatedCurrency: null,
      itemAssociatedCurrency: [],
      sNameInstitution: null,
      itemNameInstitution: [],
      bLoadingAccountType: true,
      bLoadingAssociatedCurrency: true,
      bLoadingInstitutionName: true
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.close();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 80;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 60;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 60;
      }
    },
    close() {
      this.$emit("setDialogAddAccount");
      this.bAdd = false;
      this.bLoading = false;
      this.sName = "";
      this.sAccountType = null;
      this.sAssociatedCurrency = null;
      this.sNameInstitution = null;
    },
    addAccount() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminAccount) {
            const payload = {
              sName: this.sName,
              sAccountTypeId: this.sAccountType,
              sFinancialInstitutionId: this.sNameInstitution,
              sCurrencyId: this.sAssociatedCurrency,
            },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.post(`${URI}/api/sp/v1/accounts`, payload, config)
              .then((response) => {
                this.Success(response.data.message);
                this.close();
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    validateForm() {
      this.bAdd =
        this.sName !== "" &&
        this.sAccountType !== null &&
        this.sAssociatedCurrency !== null &&
        this.sNameInstitution !== null;
    },
    getAccountType() {
      DB.get(`${URI}/api/sp/v1/account-types`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.itemAccountType = response.data.results;
          this.itemAccountType.unshift({
            sName: "Seleccionar tipo de cuenta",
            sAccountTypeId: null,
            disabled: true,
          });
          this.bLoadingAccountType = false

        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    getAssociatedCurrency() {
      DB.get(`${URI}/api/sp/v1/currencies`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.itemAssociatedCurrency = response.data.results;
          this.itemAssociatedCurrency.unshift({
            sName: "Seleccionar moneda asociada",
            sCurrencyId: null,
            disabled: true,
          });
          this.bLoadingAssociatedCurrency = false;
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    getInstitutionName() {
      DB.get(`${URI}/api/sp/v1/financial-institutions`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.itemNameInstitution = response.data.results;
          this.itemNameInstitution.unshift({
            sName: "Seleccionar institución",
            sFinancialInstitutionId: null,
            disabled: true,
          });
          this.bLoadingInstitutionName = false;
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
  },
  computed: {
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    sColorKey() {
      if (!this.sColorKey.includes("#")) {
        this.sColorKey = this.sColorKey.trim().replace("#", "");
        this.sColorKey = "#" + this.sColorKey;
      }
      this.sColorKey = this.sColorKey.toUpperCase();

      this.validateForm();
    },
    dDialogAddAccount() {
      if (this.dDialogAddAccount) {
        this.getAccountType();
        this.getAssociatedCurrency();
        this.getInstitutionName();
      }
    },
  },
};
</script>
<style scoped>
.title-add-account {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
  word-break: normal;
}

.btn-icon-color-dynamic {
  border: solid var(--primary-color-text) 1px;
  border-radius: 100%;
  margin-top: -5px;
  margin-bottom: 5px;
  height: 26px;
  width: 26px;
}

.icon-color-dynamic {
  width: 100%;
  height: 100%;
}

.btn-arrow-down-color {
  margin-top: -10px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.content-title-account {
  width: 100%;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 250px;
}

.content-btn-second {
  width: 250px;
  margin-right: 15px;
}

.content-btns-picker-color {
  width: 50%;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>